import React,{useRef,useState,useEffect,useContext} from 'react'
import {UserContext} from '../context/UserContext'
import {Link} from 'react-router-dom'
import {Alert,Container,Row,Col,Form,Button,Table,Accordion} from 'react-bootstrap'
//import FileUpload from './FileUpload'
import DataTable from 'react-data-table-component';
import axios from 'axios'
import {FaBookOpen} from 'react-icons/fa'
import ChallengeForm from './ChallengeForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBasketShopping,faEdit,faShop,faPlus,faBaseball } from '@fortawesome/free-solid-svg-icons'

import ChallengeActiveLight from './ChallengeActiveLight';

export default function Programs() {

    //const [form,setForm]=useState(false)
    const {form,setForm}= useContext(UserContext)
    const [id,setId]=useState(0)
    const [isLoading,setIsLoading]=useState(true)
    const [data,setData]=useState([])
    const [dataFlt,setDataFlt]=useState([])
    const [flt,setFlt]=useState('')
    const [sportFlt,setSportFlt]=useState('')
    const [typeFlt,setTypeFlt]=useState('')
    const [sports,setSports]=useState([])


    const columns = [
        {
            name: '#',
            selector: (row,index) =>  index+1,
            sortable: true,
            maxWidth:'5px'
        },

        {
            name: '',
            selector: row => <Button variant="secondary" onClick={()=>{showForm(row.UUID,true)}}><FontAwesomeIcon size={10} icon={faEdit}/></Button>,
            sortable: true,
            maxWidth:'5px'
        },

        {
            name: 'Ενεργό',
            selector: row =>  {
                let yourDate = new Date()
                const today=yourDate.toISOString().split('T')[0]
                const isActive=row.IsChallenge!=1 ? row.IsActive : row.DateTo<today ? 0 : row.IsActive
                return(
                    <ChallengeActiveLight isActive={isActive}/>
                )
                
            },
            sortable: true,
            maxWidth:'10px'
        }, 
        {
            name: 'Τύπος Περιεχομένου',
            selector: row =>  row.IsChallenge==1 ? 'CHALLENGE' : row.IsChallenge==2 ? 'ΧΟΡΗΓΟΣ' : 'ΑΡΘΡΟ',
            sortable: true,
            maxWidth:'180px'
        },
        {
            name: 'Όνομα',
            selector: row =>  row.Title,
            sortable: true,
             maxWidth:'650px'
        },
        {
            name: 'Δημιουργία',
            selector: row =>  row.DateCreated,
            sortable: true,
            maxWidth:'50px'
        },
        // {
        //     name: 'Ενεργό',
        //     selector: row =>  row.IsActive,
        //     sortable: true
        // }, 


        
        {
            name: 'Λήξη (μόνο για Challenges)',
            selector: row =>  row.IsChallenge==1 ? row.DateTo : '',
            sortable: true,
            maxWidth:'250px'
        },             
    ]


    useEffect(()=>{
        setIsLoading(true)
        loadData()
        loadSports()
    },[form])


    const loadData=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'admin/challenges',{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
              }            
          })
        .then(res=>{
        //console.log(res.data.results) 
          setData(res.data.results)
          setDataFlt(res.data.results)
          setIsLoading(false)
          
        }).catch(err=>{
            setIsLoading(false)
            console.log(err)
        })          
      }

      const loadSports=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'admin/sports',{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
              }            
          })
        .then(res=>{
           // console.log(res.data.results) 
          setSports(res.data.results)
          setIsLoading(false)
          
        }).catch(err=>{
            setIsLoading(false)
            console.log(err)
        })          
      }

    const showForm=(id,a)=>{
        setForm(a)
        setId(id)
    }

    const sTitle=useRef()
    const sIsChallenge=useRef()
    const sSportID=useRef()

    const sportsList=sports.map(item=>{
        return(
          <option key={item.SportID} value={item.SportID}>{item.SportName}</option>
        )
      })
      

      const clearCretiria=()=>{
        setFlt('')
        setSportFlt('')
        setTypeFlt('')
        setDataFlt([...data])
        
      }

    const search=(e)=>{
        e.preventDefault()
        var dataRes=[]
        if (flt.length>0){
            dataRes=[...data].filter(item=>item.Title.toUpperCase().includes(flt.toUpperCase()))
        }else{
            dataRes=[...data]
        }


        if (sportFlt.length>0){
            dataRes=dataRes.filter(item=>item.SportID==sportFlt)
        }

        if (typeFlt.length>0){
            dataRes=dataRes.filter(item=>item.IsChallenge==typeFlt)
        }

       setDataFlt(dataRes)
    }



    return (
        <>
            {isLoading && 

            <Alert variant="warning" >Loading, please wait....</Alert>
            }

            {!isLoading &&             
            <>
            <Alert variant="secondary"><h4><FaBookOpen/> {form ? 'Προσθήκη/Επεξεργασία' : 'Λίστα'}</h4></Alert>
                <Container fluid>

               

                {form && 
                    <>
                    <button className="btn btn-dark" onClick={()=>{showForm(0,false)}}>Λίστα</button>
                    <ChallengeForm id={id} showForm={showForm} setForm={setForm} loadData={loadData}/>
                    </>
                }

                {!form &&
                    <>
                     <button className="btn btn-dark" onClick={()=>{showForm(0,true)}}>Προσθήκη</button>
                    <br/><br/>
 
                    <Accordion >
                        <Accordion.Item eventKey="0" style={{borderColor:'black',borderWidth:'2px'}}>
                            <Accordion.Header>Φίλτρα και Αναζήτηση</Accordion.Header>
                            <Accordion.Body>
                                <Alert variant="secondary">
                                <Form onSubmit={search}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Τίτλος</Form.Label>
                                        <Form.Control  type="text"  value={flt} onChange={(e)=>setFlt(e.target.value)}/>
                                    </Form.Group>
                                    <br/>
                                    <Form.Group>
                                        <Form.Label>Τύπος Άρθρου</Form.Label>  
                                        <Form.Control as="select" value={typeFlt} onChange={(e)=>setTypeFlt(e.target.value)}>
                                            <option value=''>[Επιλέξτε Τύπο Άρθρου]</option>
                                            <option value='1'>Challenge</option>
                                            <option value='0'>Απλή Ανάρτηση</option>
                                        </Form.Control>
                                    </Form.Group>        
                                    <br/>
                                    <Form.Group>
                                    <Form.Label>Άθλημα</Form.Label>  
                                    <Form.Control  as="select" value={sportFlt} onChange={(e)=>setSportFlt(e.target.value)}>
                                        <option value=''>[Επιλέξτε Άθλημα]</option>
                                        {sportsList}
                                    </Form.Control>
                                </Form.Group>   
                                <br/>
                                    <p align="right">
                                    <Button onClick={()=>clearCretiria()} variant="secondary">Καθαρισμός</Button>
                                    &nbsp;&nbsp;
                                    <Button type='submit' variant="dark">Αναζήτηση</Button>
                                    </p>

                                    </Form>
                                </Alert>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <DataTable
                        columns={columns}
                        data={dataFlt}
                        highlightOnHover
                        //expandableRows
                        //expandableRowsComponent={ExpandedComponent}
                        //pagination
                />                        

                    </>
                
                }
                </Container>
                </>
            }
        </>
    )
}
