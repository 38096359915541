import React, {useState,useContext,createContext,useEffect,useRef}  from 'react'
import {Form,Button,Container,Card,Alert,Table,Modal,Navbar,NavDropdown,Nav,Row,Col,Accordion} from 'react-bootstrap'
import FileUploadSports from './FileUploadSports';

import axios from 'axios'
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBasketShopping,faEdit,faShop,faPlus,faBaseball,faDownload } from '@fortawesome/free-solid-svg-icons'


export default function SharedFiles() {
    const [data,setData]=useState([])
    const [isLoading,setIsLoading]=useState(false)
    const [show, setShow] = useState(false);
    const [record,setRecord]=useState({})
    const [file,setFile]=useState(null)
    const [fileName,setFileName]=useState('Please select an MP4 file')
    const [upLoading, setUpLoading]=useState('none')
    const [uploadPercentage, setUploadPercentage]=useState(0)
    const [descr,setDecsr]=useState('')


    const columns = [
        {
            name: '#',
            selector: (row,index) =>  index+1,
            sortable: true,
            maxWidth:'5px'
        },
        // {
        //     name: '',
        //     selector: row => <Button variant="secondary" onClick={()=>showModal(row.id)}><FontAwesomeIcon icon={faEdit}/></Button>,
        //     sortable: true,
        //     maxWidth:'5px'
        // },

        {
            name: 'User Name',
            selector: row =>  row.UN,
            sortable: true
        },
        {
            name: 'File Name',
            selector: row =>  row.FileName,
            sortable: true
        },
        {
            name: '',
            selector: row => <Button variant="secondary" onClick={()=>handleDownload(row.FileUUID,row.FileName)}><FontAwesomeIcon icon={faDownload}/></Button>,
            sortable: true,
            maxWidth:'5px'
        },  
        {
            name: 'Descripion',
            selector: row =>  row.Descr,
            sortable: true
        },

        {
            name: '',
            selector: row => row.AdminUserUUID==sessionStorage.getItem('userUUID') || sessionStorage.getItem('isAdmin')==1 ? <Button onClick={()=>delFile(row.FileUUID,row.FileName)} variant="danger">X</Button> : '',
            sortable: true
        },

        
        // {
        //     name: 'Default Γλώσσα',
        //     selector: row =>  row.LangName,
        //     sortable: true
        // },  
            
    ]


    useEffect(()=>{
        loadData()
    },[])



    const delFile=(uuid,filename)=>{
        if (window.confirm('Delete the file '+filename+'?')){
            axios.get(process.env.REACT_APP_API_SERVER+'admin/sharedfiles/delete/'+uuid,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
                    }            
                })
              .then(res=>{
                 //console.log(res.data.results) 
                loadData()
                setIsLoading(false)
                
              }).catch(err=>{
                  setIsLoading(false)
                  console.log(err)
              })  
        }
    }

    const handleDownload = async (uuid,filename) => {
        try {
          const response = await axios.get(process.env.REACT_APP_API_SERVER+'admin/sharedfiles/file/'+uuid, {
            responseType: "blob", // Ensures the response is treated as a Blob
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
            }
          });
    
          // Create a URL for the Blob and trigger the download
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
    
          console.log(url)

          // Set the default file name for download
          const contentDisposition = response.headers["Content-Disposition"];

          //const filename =  xfileName;
    
          link.download = filename;
          link.click();
    
          // Clean up the URL object
          window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error("Error downloading the file:", error);
        }
      };
    

    const loadData=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'admin/sharedfiles',{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
              }            
          })
        .then(res=>{
          // console.log(res.data.results) 
          setData(res.data.results)
          setIsLoading(false)
          
        }).catch(err=>{
            setIsLoading(false)
            console.log(err)
        })          
      }

    const showModal=(id)=>{
        setShow(true)
    }


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const save=(e)=>{
        e.preventDefault()
        const formData=new FormData()
        formData.append('file',file)
        formData.append('userUUID',sessionStorage.getItem('userUUID'))
        formData.append('descr',descr)



        const url=process.env.REACT_APP_API_SERVER+'admin/sharedfiles'
        try{
            setUpLoading('block')
            axios.post(url,formData,{
                headers:{
                    'Content-Type':'multipart/form-data',
                    'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
                },
                onUploadProgress: ProgressEvent=>{
                    setUploadPercentage(parseInt(Math.round(ProgressEvent.loaded*100/ProgressEvent.total)))
                }
            }).then(res=>{
                setUpLoading('none')
                document.getElementById('customFile').value=null
                
                setUploadPercentage(0)

                if (res.data.err==0){
                    alert('File Uploaded')
                    setDecsr('')
                    loadData()
                    setFile(null)
                    setShow(false)
                }else{
                    alert('An Error has occured! Please Try Again')
                }

            }).catch(err=>{
                alert('Error')
                console.log(err)
            })



            
        }catch(err){
            alert(err)
            console.log(err)
        }

    }





    const fileChanged=e=>{
        setFile(e.target.files[0])
        setFileName(e.target.files[0].name)

    }

  return (
        <>
            {isLoading &&
            <Alert variant='warning'>Loading....Please Wait</Alert>

            }

            {!isLoading &&
            <>
           
            <Alert variant="secondary">
                <table width="100%">
                    <tr>
                        <td><h5>Shared Files</h5></td>
                        <td align="right">
                        <Button  variant="dark" onClick={()=>showModal(0)}><FontAwesomeIcon icon={faPlus}/></Button>
                        </td>
                        <td width="10"></td>
                    </tr>
                </table>
                    
            </Alert>


            <DataTable
                columns={columns}
                data={data}
                highlightOnHover
                //expandableRows
                //expandableRowsComponent={ExpandedComponent}
                //pagination
                />    

            </>
            }

                <Modal show={show} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>
                <Modal.Title>
                    {record.ModalTitle}
                </Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                <Form onSubmit={save}>

                        <>
                            <Form.Group controlId="formFile" className="mb-3">
                                <Form.Label>Select File</Form.Label>
                                <Form.Control onChange={fileChanged} type="file" required id="customFile"/>
                            </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Short Description</Form.Label>
                            <Form.Control type="text" maxLength={200} required value={descr} onChange={(e)=>setDecsr(e.target.value)}/>
                        </Form.Group>
                        <br/>

                        <Row><Col></Col>
                        <Col md="auto">


                            <Button disabled={record.PWD!=record.PWD2} variant="success" type="submit" style={{width:"150px"}}>
                                Upload
                            </Button>                      
                        </Col>
                      </Row>                        
                        </>
                   </Form> 


                             
                </Modal.Body>

            </Modal>   




        </>
  )
}
