import React,{useRef,useState,useEffect,useContext} from 'react'
import {UserContext} from '../context/UserContext'
import {Link} from 'react-router-dom'
import {Alert,Container,Row,Col,Form,Button,Table,Tabs,Tab} from 'react-bootstrap'
//import FileUpload from './FileUpload'
import DataTable from 'react-data-table-component';
import axios from 'axios'
import {FaBookOpen, FaUser} from 'react-icons/fa'
import UsersForm from './UsersForm';


export default function Programs() {


    //const [form,setForm]=useState(false)
    const {form,setForm}= useContext(UserContext)
    const [id,setId]=useState(0)
    const [isLoading,setIsLoading]=useState(true)
    const [data,setData]=useState([])
    const [sports,setSports]=useState([])
    const [sportID,setSportID]=useState(0)
    


    const columns = [
        {
            name: '#',
            selector: (row,index) =>  index+1,
            sortable: true,
            maxWidth:'5px'
        },


        {
            name: 'Όνομα',
            selector: row => <Link to="#" onClick={()=>{showForm(row.UUID,true,row.SportID)}}>{row.FullName}</Link>,
            sortable: true
        },
        {
            name: 'Nickname',
            selector: row => row.Nickname,
            sortable: true
        },
        {
            name: 'Country',
            selector: row => row.Country,
            sortable: true
        },
        {
            name: 'Registration Date',
            selector: row => row.RegistrationDate.split('T')[0],
            sortable: true
        },

        
        {
            name: 'Last Login',
            selector: row => row.LogDate,
            sortable: true
        },
        {
            name: '',
            selector: (row,index) =>  <img src={loadImage(row.UUID)} width="60"/>,
            sortable: true,

        },        

        {
            name: 'Posts',
            selector: (row,index) =>  row.NumOfPosts,
            sortable: true,
            maxWidth:'5px'
        },

        {
            name: 'Ενεργό',
            selector: row =>  row.IsActive,
            sortable: true
        }, 
            
    ]


    useEffect(()=>{
        setIsLoading(true)
        loadData()
    },[form])


    const loadData=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'admin/users',{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
              }            
          })
        .then(res=>{
         //console.log(res.data.results) 
          setData(res.data.results)
          loadSports()
          setIsLoading(false)
          
        }).catch(err=>{
            setIsLoading(false)
            console.log(err)
        })          
      }


      const loadSports=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'admin/sports',{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
              }            
          })
        .then(res=>{
           // console.log(res.data.results) 
          setSports(res.data.results)
          setIsLoading(false)
          
        }).catch(err=>{
            setIsLoading(false)
            console.log(err)
        })           
      }

    const showForm=(id,a,SportID)=>{
        setSportID(SportID)
        setForm(a)
        setId(id)
    }


    const loadImage=(id)=>{
        const d=Date.now()
        //alert(`${process.env.REACT_APP_API_SERVER}files/${userID}/${d}`)
        return(`${process.env.REACT_APP_API_SERVER}files/${id}/${d}`)
      }

    const sportsList=sports.map((item,index)=>{
        

        return(
            <Tab eventKey={index} title={item.SportName} key={`sports${index}`}>
            <br/>

            <DataTable
                columns={columns}
                data={data.filter(xitem=>xitem.SportID==item.SportID)}
                highlightOnHover
                //expandableRows
                //expandableRowsComponent={ExpandedComponent}
                //pagination
                />   

          </Tab>            
        )
    })

    const dataList=data.map(item=>{
        return(
            <tr id={item.id}>
                <td><Link to="#" onClick={()=>{showForm(item.UUID,true)}}>{item.Title}</Link></td>

                <td><div dangerouslySetInnerHTML={{ __html:item.Descr}}/></td>
                <td>
                {/* <img src={`${process.env.REACT_APP_SERVER}images/programs/${item.Photo}`} width="100"/> */}
                {item.IsActive}
                </td>
            </tr>
        )
    })

    return (
        <>
            {isLoading && 

            <Alert variant="warning" >Loading, please wait....</Alert>
            }

            {!isLoading &&             
            <>
            <Alert variant="secondary"><h4><FaUser/> {form ? 'Προσθήκη/Επεξεργασία User' : 'Λίστα Users'}</h4></Alert>
                <Container fluid>

               

                {form && 
                    <>
                    <button className="btn btn-dark" onClick={()=>{showForm(0,false,0)}}>Users List</button>
                    <UsersForm id={id} showForm={showForm} setForm={setForm} loadData={loadData} SportID={sportID}/>
                    </>
                }

                {!form &&
                    <>
                     <button className="btn btn-dark" onClick={()=>{showForm(0,true)}}>Προσθήκη User</button>
                    <br/><br/>
                    
                        <Tabs>
                            {sportsList}
                        </Tabs>

                    </>
                
                }
                </Container>
                </>
            }
        </>
    )
}
