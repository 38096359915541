import React from 'react'
import "./ChallengeAvtiveLight.css";

export default function ChallengeActiveLight(props) {
  return (
    <div className="App">
     {props.isActive==1 ? <div className="colored-circle green"></div>  : <div className="colored-circle red"></div>}
      {/* <div className="colored-circle green"></div> */}
    </div>
  )
}
